import { Melody } from "./melody";
import { Row } from "./row";
import { Tile } from "./tile";

export class GameState {
    rows: Row[] = [];
    currentRow: Row;
    targetMelody: Melody;

    public constructor(targetMelody: Melody) {
        this.targetMelody = targetMelody;
        this.rows = this.createRows();
        this.rows[0].enabled = true;
        this.currentRow = this.rows[0];
    }

    public targetMelodyReached()
    {
        for (let i = 0; i < this.currentRow.tiles.length; i++)
        {
            if (this.currentRow.tiles[i].selectedNote !== this.targetMelody.notes[i].noteValue)
            {
                return false;
            }    
        }

        return true;
    }

    public currentRowFinished(): boolean
    {
        console.log('finished?', this.currentRow.tiles)
        return !this.currentRow.tiles.find(tile => tile.selectedNote === "")
    }

    public createRows(): Row[] {
        let rows: Row[] = [];

        for (let i = 0; i < 8; i++) {
            let row = new Row();

            for (let i = 0; i < 8; i++) {
                row.tiles.push(new Tile());
            }

            rows.push(row);
        }

        return rows;
    }

}