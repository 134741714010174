const C4 = require('./C4.mp3')
const D4 = require('./D4.mp3')
const E4 = require('./E4.mp3')
const F4 = require('./F4.mp3')
const G4 = require('./G4.mp3')
const A4 = require('./A4.mp3')
const B4 = require('./B4.mp3')
const C5 = require('./C5.mp3')

let notesCollection: { [key: string]: any } = { "C4": C4, "D4": D4, "E4": E4, "F4": F4, "G4": G4, "A4": A4, "B4": B4, "C5": C5 }

export default notesCollection