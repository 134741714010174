import { useState } from "react";
import "./App.css";
import Modal from "./Modal";
import { GameState } from "./models/gameState";
import { Melody } from "./models/melody";
import { Note } from "./models/note";
import { Row } from "./models/row";
import { SetGameStateAction } from "./models/SetGameStateAction";
import { Tile } from "./models/tile";
import notesCollection from "./notes/notes";

export type SetBooleanAction = React.Dispatch<React.SetStateAction<boolean>>;

const getRows = (
  game: GameState,
  setGame: SetGameStateAction,
  setMelodyIncompleteOpen: SetBooleanAction,
  setGameCompleteOpen: SetBooleanAction,
  setGameFailOpen: SetBooleanAction
) => {
  return game.rows.map((row) => {
    let tiles = row.tiles.map((tile) => {
      return (
        <div
          className="tile"
          data-animation={getCurrentAnimation(tile)}
          data-state={row.submitted ? getDataState(tile) : ""}
        >
          {getNoteSelection(row, tile, setGame)}
        </div>
      );
    });

    return (
      <div className="tile-rows">
        {tiles}
        <div
          className={
            "enter-button " + (row === game.currentRow ? "" : "hidden")
          }
        >
          <button
            className="button button4"
            onClick={async (enterButton: any) =>
              await rowSubmitted(
                game,
                setGame,
                setMelodyIncompleteOpen,
                setGameCompleteOpen,
                setGameFailOpen
              )
            }
          >
            Submit
          </button>
        </div>
      </div>
    );
  });
};

const getDataState = (tile: Tile) => {
  if (tile.rightNote) {
    return "correct";
  } else if (tile.rightPosition) {
    return "present";
  } else if (tile.absent) {
    return "absent";
  }

  return "";
};

const getCurrentAnimation = (tile: Tile) => {
  if (tile.rightNote || tile.rightPosition || tile.absent) {
    return "flip-in";
  }

  return "";
};

const rowSubmitted = async (
  game: GameState,
  setGame: SetGameStateAction,
  setMelodyIncompleteOpen: SetBooleanAction,
  setGameCompleteOpen: SetBooleanAction,
  setGameFailOpen: SetBooleanAction
) => {
  if (game.currentRowFinished()) {
    await game.targetMelody.playNotes(setGame, game, 0, () => {
      if (game.targetMelodyReached()) {
        setGameCompleteOpen(true);
      } else {
        enableNextRow(setGame, setGameFailOpen);
      }
    });
  } else {
    setMelodyIncompleteOpen(true);
  }
};

const getNoteSelection = (
  row: Row,
  tile: Tile,
  setGame: SetGameStateAction
) => {
  return (
    <div>
      {row.submitted && !row.enabled ? (
        tile.selectedNote
      ) : (
        <select
          data-state={getDataState(tile)}
          onChange={(noteSelection: any) =>
            noteChanged(noteSelection, row, tile, setGame)
          }
          onFocus={(noteSelection: any) =>
            playNote(noteSelection, row, tile, setGame)
          }
          disabled={!row.enabled}
        >
          <option selected disabled>
            {" "}
          </option>
          {Object.entries(notesCollection).map(([key, value]) => (
            <option>{key}</option>
          ))}
        </select>
      )}
    </div>
  );
};

const playNote = (
  noteSelection: any,
  row: Row,
  tile: Tile,
  setGame: SetGameStateAction
) => {
  let playerCheck: any = document.getElementById(`audio-player-${noteSelection.target.value}`);

  if (playerCheck) {
    let player: HTMLAudioElement = playerCheck;

    player.src = notesCollection[noteSelection.target.value];
    player.play();
  }
};

const noteChanged = (
  noteSelection: any,
  row: Row,
  tile: Tile,
  setGame: SetGameStateAction
) => {
  setTileSelected(noteSelection.target.value, row, tile, setGame);
  setTimeout(() => playNote(noteSelection, row, tile, setGame), 0);
};

function enableNextRow(
  setGame: SetGameStateAction,
  setGameFailOpen: SetBooleanAction
) {
  setGame((prevState) => {
    let newState = {
      ...prevState,
      currentRowFinished: prevState.currentRowFinished,
      createRows: prevState.createRows,
      targetMelodyReached: prevState.targetMelodyReached,
    };

    let currentIndex = newState.rows.indexOf(newState.currentRow);

    if (currentIndex < newState.rows.length - 1) {
      newState.rows[currentIndex + 1].enabled = true;
      newState.currentRow = newState.rows[currentIndex + 1];
      newState.rows[currentIndex].enabled = false;
      newState.rows[currentIndex].submitted = true;
    } else {
      setGameFailOpen(true);
    }

    return newState;
  });
}

function setTileSelected(
  noteSelection: string,
  row: Row,
  tile: Tile,
  setGame: SetGameStateAction
) {
  setGame((prevState) => {
    let newState = {
      ...prevState,
      currentRowFinished: prevState.currentRowFinished,
      createRows: prevState.createRows,
      targetMelodyReached: prevState.targetMelodyReached,
    };

    const targetRow = newState.rows[newState.rows.indexOf(row)];
    const targetTile = targetRow.tiles[targetRow.tiles.indexOf(tile)];

    targetTile.selectedNote = noteSelection;

    return newState;
  });
}

function getTargetMelody() {
  const tempo = 140;
  const quarterNote = (60 / tempo) * 1000;

  const targetMelody = new Melody([
    new Note("E4", quarterNote),
    new Note("E4", quarterNote),
    new Note("F4", quarterNote),
    new Note("G4", quarterNote),
    new Note("G4", quarterNote),
    new Note("F4", quarterNote),
    new Note("E4", quarterNote),
    new Note("D4", quarterNote),
  ]);
  return targetMelody;
}

function getAudioPlayers() {
  return Object.keys(notesCollection).map(note => {
    return <audio id={`audio-player-${note}`} src={notesCollection[note]} preload="true">
    Your browser does not support the
    <code>audio</code> element.
    </audio>
  });
}

function App() {
  const targetMelody = getTargetMelody();

  const [game, setGame] = useState(new GameState(targetMelody));
  const [howToPlayOpen, setHowToPlayOpen] = useState(false);
  const [melodyIncompleteOpen, setMelodyIncompleteOpen] = useState(false);
  const [gameCompleteOpen, setGameCompleteOpen] = useState(false);
  const [gameFailOpen, setGameFailOpen] = useState(false);

  return (
    <div className="App">
      <header className="App-header">
        <div id="top">
          <div>
            <svg
              onClick={() => setHowToPlayOpen(true)}
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path
                fill="white"
                d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
              ></path>
            </svg>
          </div>
          <div id="chordle-heading">Chordle</div>
          <div></div>
        </div>
        {getRows(
          game,
          setGame,
          setMelodyIncompleteOpen,
          setGameCompleteOpen,
          setGameFailOpen
        )}

       {getAudioPlayers()}   
      </header>

      <Modal
        open={howToPlayOpen}
        closeModal={() => setHowToPlayOpen(false)}
        headingText="How To Play"
      >
        It's Wordle, but with music notes. Try to guess the notes in the melody.
        If you get a right note in a right place, it will be highlighted green.
        If you get a right note in a wrong place, it will be highlighted yellow.
        Otherwise you got it totally wrong. Have fun!
      </Modal>

      <Modal
        open={melodyIncompleteOpen}
        closeModal={() => setMelodyIncompleteOpen(false)}
        headingText="Melody Incomplete"
      >
        Please select all notes of the target melody before submitting.
      </Modal>

      <Modal
        open={gameCompleteOpen}
        closeModal={() => setGameCompleteOpen(false)}
        headingText="You Got It!"
      >
        Congratulations! You have correctly identified the target melody, Ludwig
        van Beethoven's Ode to Joy.
      </Modal>

      <Modal
        open={gameFailOpen}
        closeModal={() => setGameFailOpen(false)}
        headingText="Game Over!"
      >
        Sorry, you have failed to identify the target melody. Feel free to try
        again!
      </Modal>
    </div>
  );
}

export default App;
