import React from 'react';
import './Modal.css';

type ModalProps =
  {
    headingText: string;
    children: React.ReactNode;
    closeModal: Function;
    open: boolean;
  }

let handleKeyPress = (e: any, closeModal: Function) => {
  console.log('event',e);
  if (e.charCode === 13) {
    closeModal();
  }
}

function Modal({ headingText, children, closeModal, open }: ModalProps) {
  return open ? (
    <div className="overlay" onKeyDown={(e) => handleKeyPress(e, closeModal)}>
      <div className="content">
        <header>
          <h1>{headingText}</h1>
          <svg onClick={() => closeModal()} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 0 24 24" width="24">
            <path fill="var(--color-tone-3)" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
          </svg>
        </header>
        <div id="main-content">
          {children}
        </div>
      </div>
    </div>
  ) : null;
}

export default Modal;

