import notesCollection from "../notes/notes";
import { GameState } from "./gameState";
import { Note } from "./note";
import { SetGameStateAction } from "./SetGameStateAction";
const kick = require("../notes/kick.mp3");

export class Melody {
  notes: Note[];

  constructor(notes: Note[]) {
    this.notes = notes;
  }

  public async play(setGame: SetGameStateAction, game: GameState) {
    this.playNotes(setGame, game);
  }

  public playNotes(
    setGame: SetGameStateAction,
    game: GameState,
    currentIndex: number = 0,
    callBack?: Function,
    alreadySeen:  {[note: string] : number} = {}
  ) {
    if (currentIndex < this.notes.length) {
      let playerCheck: any = document.getElementById(`audio-player-${game.currentRow.tiles[currentIndex].selectedNote}`);

      const justNotes = game.targetMelody.notes.map(note => note.noteValue);
      const noteCounts: {[note: string] : number} = {};
      
    if (Object.keys(alreadySeen).length === 0)
    {
        justNotes.forEach(note => {
           alreadySeen[note] = 0;
        }); 
    }

      justNotes.forEach(note => {
          noteCounts[note] = note in noteCounts ? ++noteCounts[note] : 1;
      });
      

      if (playerCheck) {
        let player: HTMLAudioElement = playerCheck;
        const targetNote = this.notes[currentIndex].noteValue;
        const selectedNote = game.currentRow.tiles[currentIndex].selectedNote;
        console.log('notes', selectedNote, noteCounts, alreadySeen);
      
        if (selectedNote === targetNote) {
          player.src = notesCollection[this.notes[currentIndex].noteValue];
          game.currentRow.tiles[currentIndex].rightNote = true;
          alreadySeen[selectedNote]++;
        } 
        else if (selectedNote in noteCounts && alreadySeen[selectedNote] < noteCounts[selectedNote]) {
          player.src =
            notesCollection[game.currentRow.tiles[currentIndex].selectedNote];
          game.currentRow.tiles[currentIndex].rightPosition = true;
          alreadySeen[selectedNote]++;
        } else {
          game.currentRow.tiles[currentIndex].absent = true;
          player.src = kick;
        }

        setGame({
          ...game,
          currentRow: game.currentRow,
          currentRowFinished: game.currentRowFinished,
          targetMelodyReached: game.targetMelodyReached,
          createRows: game.createRows,
        });

        player.play();
      }

      setTimeout(
        () => this.playNotes(setGame, game, ++currentIndex, callBack, alreadySeen),
        this.notes[currentIndex].noteDuration
      );
    } else if (callBack) {
      callBack();
    }
  }

  //Create a melody with arbitrary notes using the
  //same durations as this melody
  public createFromNotes(notes: string[]) {
    let newNotes: Note[] = [];

    for (var i = 0; i < this.notes.length; i++) {
      newNotes.push(new Note(notes[i], this.notes[i].noteDuration));
    }

    return new Melody(newNotes);
  }
}
